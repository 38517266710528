import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import ServicePage from "../../components/templates/ServicePage"

const query = graphql`
  {
    allContentfulInstallationImages(
      filter: {
        metadata: {
          tags: {
            elemMatch: { contentful_id: { eq: "farmFireSuppressionPonds" } }
          }
        }
      }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          id
          title
        }
      }
    }
  }
`

export default function FireWaterFeatureInstallation() {
  const title = `Fire & Water Feature` // Singular (not plural)
  const {
    allContentfulInstallationImages: { nodes: image },
  } = useStaticQuery(query)

  const imageList = image[0].image

  return (
    <Layout>
      <Navbar />
      <Seo
        title={`${title}s`}
        description={`Andreatta Waterscapes has more than 30 years of experience providing ${title} installation services. Call today to start planning your next project.`}
      />

      <ServicePage
        title={title}
        imageList={imageList}
        img={
          <StaticImage
            src="../../assets/images/12-Andreatta-Waterscapes-Fish-Pond-Central-Point-Oregon.jpg"
            quality={90}
            loading="eager"
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 1,
              height: "21rem",
            }}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Pondless Waterfall Medford Oregon"
          />
        }
        tagline={`Elevate your evening water feature experience`}
        body={[
          {
            id: 0,
            type: "paragraph",
            content: `The flicker of fire combined with the soothing sound of cascading water will take your water feature to the next level with a fire fountain. The draw of both fire and water are universal. Together, they add to the nighttime ambiance and will extend the enjoyment of your outdoor space.`,
          },
          {
            id: 1,
            type: "paragraph",
            content: `This unique and popular addition to outdoor living areas can be added to patios or decks to create a central gathering spot for get togethers, or even placed within a larger water feature. `,
          },
          {
            id: 2,
            type: "paragraph",
            content: `Check out this video to see for yourself the mesmerizing effect a fire fountain has when combined with hardscaping and landscaping.`,
          },
        ]}
      />
    </Layout>
  )
}
